import { t } from "i18next";

import { Swiper, SwiperSlide } from 'swiper/react';

function Circles(props) {
    const products = props.products;
    const maintenances = props.data.MaintenanceModal.maintenances;
    const selectedProducts = props.selectedProducts;
    const setSelectedProducts = props.setSelectedProducts;

    const setProduct = (product_id) => {
        if (selectedProducts.includes(product_id)) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts([product_id]);
        }
    }

    const openMaintenance = (element) => {
        props.data.MaintenanceModal.func.open(element, maintenances[element.id]);
    }

    const getIndexArray = () => {
        const rows = [];
        for (let i = 0; i < 12; i++) {
            rows.push(i);
        }
        return rows;
    }

    return <>
        <Swiper className="circles mb-3 mt-3"
            slidesPerView={3.5}
            grabCursor={true}
            breakpoints={{
                425: {
                    slidesPerView: 4.5,
                },
                768: {
                    slidesPerView: 8.5,
                },
                1024: {
                    slidesPerView: 8.5,
                },
                1440: {
                    slidesPerView: 13.5,
                }
            }}
        >

            {!products.length ? <>
                <div className="circles mb-3">
                    {getIndexArray().map((v, i) => {
                        return <SwiperSlide className="circle placeholder-glow" key={i}>
                            <div className="circle-content text-dark">
                                <span className="placeholder image-square" style={{ borderRadius: "50%", height: "64px", width: "64px" }}></span>
                            </div>
                            <span className="circle-title placeholder"></span>
                        </SwiperSlide>
                    })}
                </div >
            </> : <>
                {products ? products.map(element => {
                    if (!maintenances.hasOwnProperty(element.id)) {
                        return <SwiperSlide key={element.id} className="circle" onClick={() => setProduct(element.id)}>
                            {element.label === 1 ? <div className="badge game-badge hot">{t("game.hot")}</div> : <></>}
                            {element.label === 2 ? <div className="badge game-badge new">{t("game.new")}</div> : <></>}

                            <div className={selectedProducts.includes(element.id) ? "circle-content active" : "circle-content"}>
                                <img src={element.image} alt={element.name} />
                            </div>
                            <span className="circle-title">{element.name}</span>
                        </SwiperSlide>
                    } else {
                        return <SwiperSlide key={element.id} className="circle maintenance" onClick={() => openMaintenance(element)}>
                            {element.label === 1 ? <div className="badge game-badge hot">{t("game.hot")}</div> : <></>}
                            {element.label === 2 ? <div className="badge game-badge new">{t("game.new")}</div> : <></>}

                            <div className={selectedProducts.includes(element.id) ? "circle-content active" : "circle-content"}>
                                <img src={element.image} alt={element.name} />
                            </div>
                            <span className="circle-title">{element.name}</span>
                        </SwiperSlide>
                    }
                }) : <></>}
            </>}
        </Swiper>
    </>;
}

export default Circles;