import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SimpleReactValidator from "simple-react-validator";
import { UserService } from "../../Models/User/UserService";
import "./LoginModal.css";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { login } from '../../Models/Pixel/Pixel';

export const LoginModal = (props) => {
    const { t } = useTranslation();
    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        username: null,
        password: null,
        fingerprint: null,
    });

    const [errors, setErrors] = useState({
        username: null,
        password: null,
    });

    const validator = new SimpleReactValidator({
        element: false,
    });

    useEffect(() => {
        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        FingerprintJS.load().then(async fp => {
            const { visitorId } = await fp.get();
            setForm({ ...form, fingerprint: visitorId });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const validate = () => {
        return new Promise(async function (resolve, reject) {
            var $errors = {};
            for (const [key, value] of Object.entries(validator.fields)) {
                if (!value) {
                    $errors[key] = validator.errorMessages[key];
                } else {
                    $errors[key] = null;
                }
            }
            return resolve($errors);
        });
    };

    const submit = async (e) => {
        setIsSubmit(true);
        e.preventDefault();
        UserService.login(form).then(response => {
            setIsSubmit(false);
            if (!response.status) {
                toast.error(t(response.message));
                return false;
            }
            login(form.username);
            props.data.Authentication.func.updateAuthentication(response.token);
            props.data.LoginModal.func.close();
            props.data.Campaign.update();

            if (window.gtag) {
                window.gtag("event", "login", {
                    method: "Manual"
                });
            }
        }).catch(error => {
            setIsSubmit(false);
        });

        return false;
    }

    const close = () => {
        setForm({
            username: null,
            password: null,
        });
        props.data.LoginModal.func.close();
    }

    const register = () => {
        props.data.LoginModal.func.close();
        props.data.RegisterModal.func.open();
    }

    const forget_password = () => {
        props.data.LiveChat.func.open();
    }

    validator.message('username', form.username, 'required|min:6|max:20', {
        messages: {
            required: t('username.required'),
            min: t('username.min'),
            max: t('username.max'),
        },
    });

    validator.message('password', form.password, 'required|min:6', {
        messages: {
            required: t('password.required'),
            min: t('password.min'),
        },
    });

    return <Modal
        className="LoginModal"
        size="lg"
        show={props.data.LoginModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="mt-2">

            <Form onSubmit={submit} noValidate>
                <button type="button" className="btn-close" aria-label="Close" onClick={props.data.LoginModal.func.close}></button>
                <Container>
                    <Row>
                        <Col xs={12} className="mb-2"><h3 className="modal-title mt-3">{t('login.title')}</h3></Col>
                        {/* Email Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="login_username" label={t('identify')}>
                                <Form.Control
                                    name="username"
                                    type="text"
                                    value={form.username == null ? '' : form.username}
                                    onChange={handleInputs("username")}
                                    placeholder=" "
                                    isInvalid={form.username !== null && errors.username !== null}
                                    isValid={form.username !== null && errors.username === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        {/* Password Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="login_password" label={t('password')}>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    value={form.password == null ? '' : form.password}
                                    onChange={handleInputs("password")}
                                    placeholder=" "
                                    isInvalid={form.password !== null && errors.password !== null}
                                    isValid={form.password !== null && errors.password === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Col xs={12} className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check className="fs-smaller" type="checkbox" label={t('remember_me')} />
                                    </Form.Group>
                                </div>
                                <div className="">
                                    <span className="fs-smaller text-link" onClick={forget_password} href="#">{t('forget_password')}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} className="mb-3">
                            <div className="d-grid gap-2">
                                <Button type="submit" disabled={isSubmit}>
                                    {t('login.submit')}
                                </Button>
                            </div>
                        </Col>

                        <Form.Group as={Col} xs={12} className="mb-3">
                            <div className="w-100 text-center">
                                <Form.Text className="text-muted">
                                    <Trans i18nKey="login.register_now">
                                        Don’t have an account? <span className="text-link" onClick={register}>Create account</span>
                                    </Trans>
                                </Form.Text>
                            </div>
                        </Form.Group>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
    </Modal>
}