import { Badge, Button, Card, Col, Container, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import "./Withdrawal.css";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { WalletService } from "../../../Models/User/WalletService";
import bank_icon from '../../../Assets/Cashier/bank.png';
import amount_icon from '../../../Assets/Cashier/amount.png';
import percent_icon from '../../../Assets/Cashier/percent-icon.png';
import drop_icon from '../../../Assets/Cashier/drop-icon.png';
import increase_icon from '../../../Assets/Cashier/increase-icon.png';
import gift_icon from '../../../Assets/Cashier/gift-icon.png';
import no_icon from '../../../Assets/Cashier/no-icon.png';
import yes_icon from '../../../Assets/Cashier/yes-icon.png';
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";
import Keypad from "../../Inputs/Keypad/Keypad";
import banner from "./banner.jpg";

export const Withdrawal = (props) => {
    const BANK = "bank", CRYPTO = "crypto", EWALLET = "ewallet";
    const { isMobile } = props.data;
    const CashierModal = props.data.CashierModal;
    const MobileSidebar = props.data.MobileSidebar;
    const helpers = props.data.helpers;
    const user = props.user;
    const [isSubmit, setIsSubmit] = useState(false);
    const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
    const [isCheckBank, setIsCheckBank] = useState(false);
    const [isPreWithdrawModal, setIsPreWithdrawModal] = useState(false);
    const [tab, setTab] = useState(BANK);
    const [currency, setCurrency] = useState(null);
    const toast = props.data.toast;

    const userWallets = user.user_wallets.map(wallet => {
        return {
            name: t(helpers.getWalletType(wallet.type)) + " (" + user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2) + ")",
            image: "/assets/" + helpers.getWalletType(wallet.type) + ".png",
            value: wallet.id,
            label: t(helpers.getWalletType(wallet.type)),
            custom: user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2)
        };
    });

    const [form, setForm] = useState({
        wallet: (user.previous_wallet) ? user.previous_wallet.id : null,
        bank: null,
        name: user.fullname ?? null,
        account: null,
        amount: null,
    });

    const [errors, setErrors] = useState({
        wallet: null,
        bank: null,
        name: null,
        account: null,
        amount: null,
    });

    useEffect(() => {
        user.user_wallets.map(wallet => {
            if (parseInt(wallet.id) === parseInt(form.wallet)) {
                setMaxWithdrawalAmount(wallet.withdrawable_balance);
            }
            return true;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.wallet])


    const updateWallet = value => {
        setForm({ ...form, wallet: value });
        return true;
    }

    const handleInputs = name => event => {
        if (name === "bank") {
            setIsCheckBank(true);
            WalletService.withdrawal_bank({ bank: event.value }).then(response => {
                if (response.status && response.data) {
                    setForm({ ...form, account: response.data.account, name: response.data.name, bank: response.data.bank });
                    setCurrency(response.currency);
                } else {
                    setForm({ ...form, account: null, name: user.full_name ?? null, bank: event.value });
                    setCurrency(response.currency);
                }
                setIsCheckBank(false);
            })
            return true;
        }

        setForm({ ...form, [name]: event.target.value });
    };

    useEffect(() => {
        const validator = new SimpleReactValidator({
            element: false,
            validators: {
                insufficient_balance: {
                    rule: (val, params, validator) => {
                        return parseFloat(val) <= parseFloat(params[0]);
                    },
                }
            }
        });

        validator.message('wallet', form.wallet, 'required', {
            messages: {
                required: t('wallet.required'),
            },
        });

        validator.message('bank', form.bank, 'required', {
            messages: {
                required: t('bank.required'),
            },
        });
        validator.message('name', form.name, 'required', {
            messages: {
                required: t('name.required'),
            },
        });

        if (tab === EWALLET) {
            var customerMaxWithdraw = user.max_withdrawal;
            if (customerMaxWithdraw > 2000) {
                customerMaxWithdraw = 2000;
            }
            validator.message('amount', form.amount, 'required|numeric|min:' + user.min_withdrawal + ',num|max:' + customerMaxWithdraw + ',num|insufficient_balance:' + maxWithdrawalAmount + '', {
                messages: {
                    required: t('amount.required'),
                    numeric: t('amount.numeric'),
                    min: t('amount.min'),
                    max: t('amount.max'),
                    insufficient_balance: t('amount.insufficient_balance'),
                },
            });

            validator.message('account', form.account, 'required|min:12|max:12', {
                messages: {
                    required: t('account.required'),
                    min: t('account.min'),
                    max: t('account.max'),
                },
            });

        } else {
            validator.message('amount', form.amount, 'required|numeric|min:' + user.min_withdrawal + ',num|max:' + user.max_withdrawal + ',num|insufficient_balance:' + maxWithdrawalAmount + '', {
                messages: {
                    required: t('amount.required'),
                    numeric: t('amount.numeric'),
                    min: t('amount.min'),
                    max: t('amount.max'),
                    insufficient_balance: t('amount.insufficient_balance'),
                },
            });

            validator.message('account', form.account, 'required', {
                messages: {
                    required: t('account.required'),
                },
            });

        }

        const validate = () => {
            return new Promise(async function (resolve, reject) {
                var $errors = {};
                for (const [key, value] of Object.entries(validator.fields)) {
                    if (!value) {
                        $errors[key] = validator.errorMessages[key];
                    } else {
                        $errors[key] = null;
                    }
                }
                return resolve($errors);
            });
        }

        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const withdrawal = async (e) => {
        e.preventDefault();

        if (errors["wallet"]) {
            return toast.error(errors["account"]);
        }

        if (errors["bank"]) {
            return toast.error(errors["bank"]);
        }

        if (errors["account"]) {
            return toast.error(errors["account"]);
        }

        if (errors["name"]) {
            return toast.error(errors["name"]);
        }

        if (errors["amount"]) {
            return toast.error(errors["amount"]);
        }

        setIsSubmit(true);
        // User Identifies verification
        if (user.user_identifies.length > 0) {
            props.data.IdentifyVerify.func.open();
            setIsSubmit(false);
            return false;
        }

        if (user.level_point > 0) {
            var level_point = parseFloat(user.level_point);
            if (user.point_to_maintain > 0) {
                var percentage_to_maintain = (level_point / user.point_to_maintain) * 100;
                if (percentage_to_maintain >= 70 && percentage_to_maintain <= 100) {
                    setIsPreWithdrawModal('maintain');
                    setIsSubmit(false);
                    return false;
                }
            }
            if (user.point_to_upgrade > 0) {
                var percentage_to_upgrade = (level_point / user.point_to_upgrade) * 100;
                if (percentage_to_upgrade >= 70 && percentage_to_upgrade <= 100) {
                    setIsPreWithdrawModal('upgrade');
                    setIsSubmit(false);
                    return false;
                }
            }
        }

        submit();
        setIsSubmit(false);
        return false;
    }

    const submit = () => {
        setIsPreWithdrawModal(false);

        WalletService.withdrawal({ ...form, is_crypto: tab === CRYPTO }).then(response => {
            if (response.status) {
                toast.success(t(response.message));
                setForm({
                    wallet: (user.previous_wallet) ? user.previous_wallet.id : null,
                    bank: (user.user_bank) ? user.user_bank.bank : null,
                    name: (user.user_bank) ? user.user_bank.name : ((user.full_name) ? user.full_name : null),
                    account: (user.user_bank) ? user.user_bank.account : null,
                    amount: null,
                });
            } else {
                toast.error(t(response.message, response.params));
            }

            props.data.Authentication.func.get();
        });
    }

    if (isPreWithdrawModal === "maintain") {
        return <>
            <Modal.Header>
                <Container className="text-center">
                    <span className="title">{t('withdrawal.title')}</span>
                </Container>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body>
                        <h5 className="text-center mb-3">{t("withdrawal.maintain.title")}</h5>
                        <h6 className="text-center text-grey fs-small mb-4">{t("withdrawal.maintain.sub_title", {
                            point: user.point_to_maintain - parseFloat(user.level_point),
                            rank: t(helpers.getRank(user)),
                            date: moment().endOf('month').format('DD/MM/YYYY')
                        })}</h6>
                        <Card className="border mb-4">
                            <Card.Body>
                                <Row className="justify-content-center align-items-center fs-smaller">
                                    <Col className="d-none d-md-flex align-items-center justify-content-center" md={2}>
                                        <img className="me-2" width={64} src={percent_icon} alt="" />
                                    </Col>
                                    <Col xs={12} md={10}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col className="px-1 mb-2" xs={12}>
                                                <h6 className="d-flex align-items-center">
                                                    <img className="me-2 d-md-none" width={16} src={percent_icon} alt="" />
                                                    {t("withdrawal.maintain.rebate_rate_will_be_downgrade")}
                                                </h6>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("slots")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["slot"]}% {t("withdrawal.maintain.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={drop_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getPreviousRank(user)]["slot"]}% {t("withdrawal.maintain.rebates")}</span>
                                                </div>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("sportsbook")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["sportsbook"]}% {t("withdrawal.maintain.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={drop_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getPreviousRank(user)]["sportsbook"]}% {t("withdrawal.maintain.rebates")}</span>
                                                </div>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("live_dealer")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["live_dealer"]}% {t("withdrawal.maintain.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={drop_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getPreviousRank(user)]["live_dealer"]}% {t("withdrawal.maintain.rebates")}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border mb-4">
                            <Card.Body className="px-2">
                                <Row className="justify-content-center align-items-center fs-smaller">
                                    <Col className="d-none d-md-flex align-items-center justify-content-center" md={2}>
                                        <img className="me-2" width={64} src={gift_icon} alt="" />
                                    </Col>
                                    <Col xs={12} md={10}>
                                        {(helpers.user_benefits[helpers.getRank(user)]['benefits'].filter(value => {
                                            return !helpers.user_benefits[helpers.getPreviousRank(user)]['benefits'].includes(value);
                                        }).map((value, i) => {
                                            return <div key={value} className={(i + 1 === helpers.user_benefits[helpers.getRank(user)]['benefits'].filter(value => {
                                                return !helpers.user_benefits[helpers.getPreviousRank(user)]['benefits'].includes(value);
                                            }).length) ? "" : "mb-2"}><img className="me-2" width={16} src={no_icon} alt="" />{t(value)}</div>
                                        }))}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border mb-4">
                            <Card.Body className="px-2 pt-4">
                                <div className="position-relative my-2 mb-4">
                                    {
                                        user.point_to_maintain > 0 && user.point_to_upgrade > 0 ? <Badge pill bg="blue" className="maintain-remain">
                                            {t('reward.to_remain_level', { rank: t(helpers.getRank(user)) })}
                                        </Badge> : <></>
                                    }

                                    <div className="d-flex align-items-center">
                                        <div className={user.point_to_upgrade === 0 ? "w-100" : "w-80"}>
                                            <ProgressBar className="maintain-progressbar" now={props.data.Authentication.func.getPercentageToUpgrade()} />

                                            <div className="requie_points">
                                                <div className="init_level_point">
                                                    {helpers.number_format(0)}
                                                </div>

                                                {user.point_to_maintain > 0 ? <div className={user.point_to_upgrade === 0 ? "maintain_level_point final" : "maintain_level_point"}>
                                                    {helpers.number_format(user.point_to_maintain, 0)}
                                                </div> : <></>}

                                                {user.point_to_upgrade > 0 ? <div className="upgrade_level_point">
                                                    {helpers.number_format(user.point_to_upgrade, 0)}
                                                </div> : <></>}
                                            </div>
                                        </div>

                                        <div className={user.point_to_upgrade === 0 ? "d-none" : "w-20 ms-3 text-center"} >
                                            <img className="reward-image" src={`/assets/ranks/${helpers.getNextRankImage(user)}`} width="100%" alt={helpers.getNextRank(user)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="fs-smaller text-grey">{t("withdrawal.maintain.rank_helper", {
                                    point: helpers.number_format(parseFloat(user.level_point), 0),
                                    point_to_maintain: helpers.number_format(user.point_to_maintain, 0),
                                    rank: t(helpers.getRank(user)),
                                })}</div>
                            </Card.Body>
                        </Card>

                        <div className="d-grid gap-2">
                            <Button as={Link} to="/rewards" className="fs-small fw-bold cancel_event" variant="primary" onClick={CashierModal.func.close}>
                                {t("withdrawal.maintain.benefits_of_ranks", {
                                    rank: t(helpers.getRank(user)),
                                })}
                            </Button>
                            <span className="text-center fs-smaller text-grey py-3 cursor-pointer continue_event" onClick={submit}>{t("withdrawal.maintain.continue_to_withdraw")}</span>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </>
    }

    if (isPreWithdrawModal === "upgrade") {
        return <>
            <Modal.Header>
                <Container className="text-center">
                    <span className="title">{t('withdrawal.title')}</span>
                </Container>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body>
                        <h5 className="text-center mb-3">{t("withdrawal.upgrade.title", { rank: t(helpers.getNextRank(user)) })}</h5>
                        <h6 className="text-center text-grey fs-small mb-4">{t("withdrawal.upgrade.sub_title", {
                            point: user.point_to_upgrade - parseFloat(user.level_point),
                            rank: t(helpers.getRank(user)),
                            next_rank: t(helpers.getNextRank(user)),
                            date: moment().endOf('month').format('DD/MM/YYYY')
                        })}</h6>
                        <Card className="border mb-4">
                            <Card.Body>
                                <Row className="justify-content-center align-items-center fs-smaller">
                                    <Col className="d-none d-md-flex align-items-center justify-content-center" md={2}>
                                        <img className="me-2" width={64} src={percent_icon} alt="" />
                                    </Col>
                                    <Col xs={12} md={10}>
                                        <Row className="justify-content-center align-items-center">
                                            <Col className="px-1 mb-2" xs={12}>
                                                <h6 className="d-flex align-items-center">
                                                    <img className="me-2 d-md-none" width={16} src={percent_icon} alt="" />
                                                    {t("withdrawal.upgrade.rebate_rate_will_be_upgrade")}
                                                </h6>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("slots")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["slot"]}% {t("withdrawal.upgrade.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={increase_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getNextRank(user)]["slot"]}% {t("withdrawal.upgrade.rebates")}</span>
                                                </div>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("sportsbook")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["sportsbook"]}% {t("withdrawal.upgrade.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={increase_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getNextRank(user)]["sportsbook"]}% {t("withdrawal.upgrade.rebates")}</span>
                                                </div>
                                            </Col>
                                            <Col className="px-1" xs={4}>
                                                <div>{t("live_dealer")}</div>
                                                <div className="cut-text">{helpers.user_benefits[helpers.getRank(user)]["live_dealer"]}% {t("withdrawal.upgrade.rebates")}</div>
                                                <div className="d-flex align-items-center">
                                                    <img width={12} src={increase_icon} alt="" />
                                                    <span className="ms-1">{helpers.user_benefits[helpers.getNextRank(user)]["live_dealer"]}% {t("withdrawal.upgrade.rebates")}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border mb-4">
                            <Card.Body className="px-2">
                                <Row className="justify-content-center align-items-center fs-smaller">
                                    <Col className="d-none d-md-flex align-items-center justify-content-center" md={2}>
                                        <img className="me-2" width={64} src={gift_icon} alt="" />
                                    </Col>
                                    <Col xs={12} md={10}>
                                        {(helpers.user_benefits[helpers.getNextRank(user)]['benefits'].filter(value => {
                                            return !helpers.user_benefits[helpers.getRank(user)]['benefits'].includes(value);
                                        }).map((value, i) => {
                                            return <div key={value} className={(i + 1 === helpers.user_benefits[helpers.getNextRank(user)]['benefits'].filter(value => {
                                                return !helpers.user_benefits[helpers.getRank(user)]['benefits'].includes(value);
                                            }).length) ? "" : "mb-2"}><img className="me-2" width={16} src={yes_icon} alt="" />{t(value)}</div>
                                        }))}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="border mb-4">
                            <Card.Body className="px-2 pt-4">
                                <div className="position-relative my-2 mb-4">
                                    {
                                        user.point_to_maintain > 0 && user.point_to_upgrade > 0 ? <Badge pill bg="blue" className="maintain-remain">
                                            {t('reward.to_remain_level', { rank: t(helpers.getNextRank(user)) })}
                                        </Badge> : <></>
                                    }

                                    <div className="d-flex align-items-center">
                                        <div className={user.point_to_upgrade === 0 ? "w-100" : "w-80"}>
                                            <ProgressBar className="maintain-progressbar" now={props.data.Authentication.func.getPercentageToUpgrade()} />

                                            <div className="requie_points">
                                                <div className="init_level_point">
                                                    {helpers.number_format(0)}
                                                </div>

                                                {user.point_to_maintain > 0 ? <div className={user.point_to_upgrade === 0 ? "maintain_level_point final" : "maintain_level_point"}>
                                                    {helpers.number_format(user.point_to_maintain, 0)}
                                                </div> : <></>}

                                                {user.point_to_upgrade > 0 ? <div className="upgrade_level_point">
                                                    {helpers.number_format(user.point_to_upgrade, 0)}
                                                </div> : <></>}
                                            </div>
                                        </div>

                                        <div className={user.point_to_upgrade === 0 ? "d-none" : "w-20 ms-3 text-center"} >
                                            <img className="reward-image" src={`/assets/ranks/${helpers.getNextRankImage(user)}`} width="100%" alt={helpers.getNextRank(user)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="fs-smaller text-grey">{t("withdrawal.upgrade.rank_helper", {
                                    point: helpers.number_format(parseFloat(user.level_point), 0),
                                    point_to_upgrade: helpers.number_format(user.point_to_upgrade, 0),
                                    rank: t(helpers.getNextRank(user)),
                                })}</div>
                            </Card.Body>
                        </Card>

                        <div className="d-grid gap-2">
                            <Button as={Link} to="/rewards" className="fs-small fw-bold cancel_event" variant="primary" onClick={CashierModal.func.close}>
                                {t("withdrawal.upgrade.benefits_of_ranks", {
                                    rank: t(helpers.getNextRank(user)),
                                })}
                            </Button>
                            <span className="text-center fs-smaller text-grey py-3 continue_event cursor-pointer" onClick={submit}>{t("withdrawal.upgrade.continue_to_withdraw")}</span>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </>
    }

    return <>
        <Modal.Header closeButton>
            <button type="button" className="btn-back" aria-label="Back" onClick={CashierModal.func.wallet}></button>
            <Container className="text-center">
                <span className="title">{t('withdrawal.title')}</span>
            </Container>
            <button type="button" className="btn-close" aria-label="Close" onClick={CashierModal.func.close}></button>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Container>
                <Card className="mb-3">
                    <Card.Body>
                        <span className="d-block fs-small">
                            {t('withdrawal.current_balance', { currency: user.currency, balance: helpers.number_format(user.balance, 2) })}
                        </span>
                        <span className="d-block fs-small font-bold mt-1">
                            {t('withdrawal.withdrawal_details')}
                        </span>
                        <span className="d-block fs-small">
                            {t('withdrawal.minimun_amount')} <b className="font-bold">{user.min_withdrawal}</b>
                        </span>
                        <span className="d-block fs-small">
                            {t('withdrawal.remaining_withdrawal_times_per_day', { count: user.remaining_withdrawal_times_per_day })}
                        </span>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body>
                        <Form onSubmit={withdrawal} noValidate>
                            <div className="mb-3">
                                <div className="wallets-selections">
                                    {userWallets.map((v, i) => {
                                        return <div className={v.value === form.wallet ? "wallet-selection selected " : "wallet-selection "} key={i} onClick={() => updateWallet(v.value)}>
                                            {v.image ? <>
                                                <div className={v.label ? "wallet-selection-image max-width-32px" : "wallet-selection-image"}>
                                                    <img src={v.image} alt={v.image} />
                                                </div>
                                            </> : <></>}
                                            {v.label ? <>
                                                <div className="wallet-selection-label">
                                                    {v.label}
                                                </div>
                                            </> : <></>}
                                            {v.custom ? <>
                                                <div className="wallet-selection-custom">
                                                    {v.custom}
                                                </div>
                                            </> : <></>}
                                        </div>
                                    })}
                                </div>
                            </div>

                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button type="button" href="#" className={tab === BANK ? "nav-link active" : "nav-link"} onClick={() => setTab(BANK)}>{t("Bank")}</button>
                                </li>
                                <li className="nav-item">
                                    <button type="button" href="#" className={tab === EWALLET ? "nav-link active" : "nav-link"} onClick={() => setTab(EWALLET)}>{t("e-Wallet")}</button>
                                </li>
                            </ul>

                            {tab === BANK && <div className="select-bank mb-3">
                                <div className="mb-2">
                                    <div className="label mb-3">
                                        <img className="icon cashier-icon" src={bank_icon} alt="bank" />
                                        <span className="ms-2 fs-small font-bold text-grey">{t('withdrawal.bank')}</span>
                                    </div>
                                    <Select
                                        className="fs-small font-bold text-grey"
                                        value={
                                            helpers.getBanks().map((bank) => { return { value: bank, label: bank } })
                                                .filter(function (option) {
                                                    return option.value === form.bank;
                                                })
                                        }
                                        onChange={handleInputs("bank")}
                                        options={helpers.getBanks().map((bank) => { return { value: bank, label: bank } })}
                                        placeholder={t('withdrawal.please_select')}
                                        styles={helpers.reactSelectStyles()}
                                        isSearchable={false}
                                        readOnly={isCheckBank}
                                    >
                                    </Select>
                                </div>

                                <div className="mb-2">
                                    <div className="label">
                                        <span className="fs-small font-bold text-grey">{t('withdrawal.bank_account_number')}</span>
                                    </div>

                                    <Form.Control
                                        type="text"
                                        className="form-control fs-small font-bold text-grey"
                                        value={form.account === null ? '' : form.account}
                                        placeholder=" "
                                        isInvalid={form.account !== null && errors.account !== null}
                                        isValid={form.account !== null && errors.account === null}
                                        autoComplete="none"
                                        onChange={handleInputs("account")}
                                        readOnly={isCheckBank}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.account}</Form.Control.Feedback>
                                </div>

                                <div className="mb-2">
                                    <div className="label">
                                        <span className="fs-small font-bold text-grey">{t('withdrawal.bank_account_name')}</span>
                                    </div>

                                    <Form.Control
                                        type="text"
                                        className="form-control fs-small font-bold text-grey"
                                        value={form.name == null ? '' : form.name}
                                        placeholder=" "
                                        isInvalid={form.name !== null && errors.name !== null}
                                        isValid={form.name !== null && errors.name === null}
                                        autoComplete="none"
                                        onChange={handleInputs("name")}
                                        readOnly={user.full_name ? true : isCheckBank}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </div>
                            </div>}

                            {tab === CRYPTO && <div className="select-bank mb-3">
                                <div className="mb-2">
                                    <div className="label mb-3">
                                        <img className="icon cashier-icon" src={bank_icon} alt="bank" />
                                        <span className="ms-2 fs-small font-bold text-grey">{t('Crypto')}</span>
                                    </div>
                                    <Select
                                        className="fs-small font-bold text-grey"
                                        value={
                                            helpers.getCryptos().map((bank) => { return { value: bank, label: bank } })
                                                .filter(function (option) {
                                                    return option.value === form.bank;
                                                })
                                        }
                                        onChange={handleInputs("bank")}
                                        options={helpers.getCryptos().map((bank) => { return { value: bank, label: bank } })}
                                        placeholder={t('withdrawal.please_select')}
                                        styles={helpers.reactSelectStyles()}
                                        isSearchable={false}
                                        readOnly={isCheckBank}
                                    >
                                    </Select>
                                </div>

                                <div className="mb-2">
                                    <div className="label">
                                        <span className="fs-small font-bold text-grey">{t('Crypto Address')}</span>
                                    </div>

                                    <Form.Control
                                        type="text"
                                        className="form-control fs-small font-bold text-grey"
                                        value={form.account == null ? '' : form.account}
                                        placeholder=" "
                                        isInvalid={form.account !== null && errors.account !== null}
                                        isValid={form.account !== null && errors.account === null}
                                        autoComplete="none"
                                        onChange={handleInputs("account")}
                                        readOnly={isCheckBank}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.account}</Form.Control.Feedback>
                                </div>
                            </div>}


                            {tab === EWALLET && <div className="select-bank mb-3">
                                <div className="mb-2">
                                    <div className="label mb-3">
                                        <img className="icon cashier-icon" src={bank_icon} alt="bank" />
                                        <span className="ms-2 fs-small font-bold text-grey">{t('e-Wallet')}</span>
                                    </div>
                                    <Select
                                        className="fs-small font-bold text-grey"
                                        value={
                                            helpers.getEWallets().map((bank) => { return { value: bank, label: bank } })
                                                .filter(function (option) {
                                                    return option.value === form.bank;
                                                })
                                        }
                                        onChange={handleInputs("bank")}
                                        options={helpers.getEWallets().map((bank) => { return { value: bank, label: bank } })}
                                        placeholder={t('withdrawal.please_select')}
                                        styles={helpers.reactSelectStyles()}
                                        isSearchable={false}
                                        readOnly={isCheckBank}
                                    >
                                    </Select>
                                </div>


                                {form.bank == "Touch 'n Go" && <>
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <img src="/assets/eWallet-tng.png" className="w-50" />
                                        </div>
                                    </div>
                                </>}
                                <div className="mb-2">
                                    <div className="label">
                                        <span className="fs-small font-bold text-grey">{t('withdrawal.e-wallet_account_number')}</span>
                                    </div>

                                    <Form.Control
                                        type="text"
                                        className="form-control fs-small font-bold text-grey"
                                        value={form.account === null ? '' : form.account}
                                        placeholder=" "
                                        isInvalid={form.account !== null && errors.account !== null}
                                        isValid={form.account !== null && errors.account === null}
                                        autoComplete="none"
                                        onChange={handleInputs("account")}
                                        readOnly={isCheckBank}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.account}</Form.Control.Feedback>
                                </div>

                                <div className="mb-2">
                                    <div className="label">
                                        <span className="fs-small font-bold text-grey">{t('withdrawal.account_name')}</span>
                                    </div>

                                    <Form.Control
                                        type="text"
                                        className="form-control fs-small font-bold text-grey"
                                        value={form.name == null ? '' : form.name}
                                        placeholder=" "
                                        isInvalid={form.name !== null && errors.name !== null}
                                        isValid={form.name !== null && errors.name === null}
                                        autoComplete="none"
                                        onChange={handleInputs("name")}
                                        readOnly={user.full_name ? true : isCheckBank}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </div>
                            </div>}
                            <div className="input-amount mb-3">
                                <div className="label">
                                    <img className="icon cashier-icon" src={amount_icon} alt="amount" />
                                    <span className="text-grey font-bold">
                                        {t('transfer.amount')}
                                        {tab == EWALLET && <span className="text-danger">({t('withdrawal.max_withdrawal', { amount: helpers.number_format(2000, 2) })})</span>}
                                    </span>
                                </div>
                                <Keypad value={form.amount} error={errors.amount} onChange={handleInputs("amount")} isMobile={isMobile} />
                                {tab === CRYPTO && currency && form.amount > 0 && <>
                                    {t("approximate")} {currency.code} {(form.amount / currency.withdraw_rate).toFixed(2)}
                                </>}
                            </div>


                            <div className="d-grid gap-2">
                                <Button className="submit_event" variant="blue" type="submit" disabled={isSubmit}>
                                    {t('withdrawal.submit')}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                    <Link to="/referral" onClick={() => { CashierModal.func.close(); MobileSidebar.func.close(); }} style={{ marginBottom: "1rem", marginTop: "1rem", textAlign: "center" }}>
                        <img className="w-sm-100 w-lg-75" src={banner} alt="refer a friend" />
                    </Link>
                </Card>
            </Container>
        </Modal.Body>
    </>;
}