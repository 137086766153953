import i18next from "i18next";

export default function helpers() {

    const number_format = (number, decimals, dec_point, thousands_point) => {
        if (number === null || !isFinite(number)) {
            return t("loading");
        }

        if (!decimals) {
            var len = number.toString().split('.').length;
            decimals = len > 1 ? len : 0;
        }

        if (!dec_point) {
            dec_point = '.';
        }

        if (!thousands_point) {
            thousands_point = ',';
        }

        number = parseFloat(number).toFixed(decimals);

        number = number.replace(".", dec_point);

        var splitNum = number.split(dec_point);
        splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
        number = splitNum.join(dec_point);

        return number;
    }

    const getRank = (user) => {
        if (user) {
            if (user.level === 1) {
                return "blue";
            }
            if (user.level === 2) {
                return "silver";
            }
            if (user.level === 3) {
                return "gold";
            }
            if (user.level === 4) {
                return "platinum";
            }
            if (user.level === 5) {
                return "legend";
            }
        }
    }

    const getPreviousRank = (user) => {
        if (user) {
            if (user.level === 2) {
                return "blue";
            }
            if (user.level === 3) {
                return "silver";
            }
            if (user.level === 4) {
                return "gold";
            }
            if (user.level === 5) {
                return "platinum";
            }
        }
    }

    const getNextRank = (user) => {
        if (user) {
            if (user.level === 1) {
                return "silver";
            }
            if (user.level === 2) {
                return "gold";
            }
            if (user.level === 3) {
                return "platinum";
            }
            if (user.level === 4) {
                return "legend";
            }
        }
    }

    const getRankImage = (user) => {
        if (user) {
            return getRank(user) + "-" + getLocale() + ".png"
        }
    }

    const getNextRankImage = (user) => {
        if (user) {
            return getNextRank(user) + "-" + getLocale() + ".png"
        }
    }

    const getLocale = () => {
        return i18next.language;
    }

    const getTransactionType = (type) => {
        if (parseInt(type) === 1) {
            return "deposit";
        }
        if (parseInt(type) === 2) {
            return "withdrawal";
        }
        if (parseInt(type) === 3) {
            return "bonus";
        }
        if (parseInt(type) === 4) {
            return "adjust";
        }
    }

    const getWalletType = (type) => {
        if (parseInt(type) === 1) {
            return "sportsbook";
        }
        if (parseInt(type) === 2) {
            return "slots";
        }
        if (parseInt(type) === 3) {
            return "live_dealer";
        }
        if (parseInt(type) === 4) {
            return "aviator";
        }
    }

    const walletTypes = () => {
        return {
            1: 'sportsbook',
            2: 'slots',
            3: 'live_dealer',
            4: 'aviator',
        }
    }

    const getTransactionStatus = (type) => {
        if (parseInt(type) === 0) {
            return "in_progress";
        }
        if (parseInt(type) === 1) {
            return "in_progress";
        }
        if (parseInt(type) === 2) {
            return "success";
        }
        if (parseInt(type) === 3) {
            return "reject";
        }
        if (parseInt(type) === 4) {
            return "processing";
        }
        if (parseInt(type) === 5) {
            return "cancel";
        }
    }

    const getPaymentType = (type) => {
        if (parseInt(type) === 1) {
            return "instant_transfer";
        }
        if (parseInt(type) === 2) {
            return "surepay88";
        }
        if (parseInt(type) === 3) {
            return "paytrust88";
        }
        if (parseInt(type) === 4) {
            return "ezziepay";
        }
        if (parseInt(type) === 5) {
            return "adjustment";
        }
        if (parseInt(type) === 6) {
            return "duitnow";
        }
    }

    const getPaymentCategory = (category) => {
        if (parseInt(category) === 1) {
            return "FPX";
        }
        if (parseInt(category) === 2) {
            return "Eeziepay";
        }
        if (parseInt(category) === 3) {
            return "Surepay88";
        }
        if (parseInt(category) === 4) {
            return "Paytrust88";
        }
        if (parseInt(category) === 5) {
            return "Local Bank Transfer";
        }
        if (parseInt(category) === 6) {
            return "Surepay88";
        }
        if (parseInt(category) === 7) {
            return "Eeziepay";
        }
        if (parseInt(category) === 8) {
            return "Eeziepay";
        }
        if (parseInt(category) === 9) {
            return "Direpay";
        }
    }

    const getBanks = () => {
        return [
            "Affin Bank Berhad",
            "Agrobank",
            "Alliance Bank Malaysia Berhad",
            "AmBank",
            "Bank Islam Malaysia",
            "Bank Muamalat Malaysia",
            "Bank Rakyat",
            "Bank Simpanan Nasional",
            "CIMB Bank",
            "Hong Leong Bank",
            "HSBC Bank",
            "Maybank",
            "OCBC Bank",
            "Public Bank Berhad",
            "RHB Bank",
            "United Overseas Bank",
            "Aeon Bank",
            "GX Bank"
        ];
    }

    const getEWallets = () => {
        return [
            "Touch 'n Go",
        ];
    }

    const getCryptos = () => {
        return [
            "TRC20-USDT",
            "TRC20-USDC",
        ];
    }

    const reactSelectStyles = () => {
        return {
            option: (v) => {
                return { ...v };
            },
            input: (v) => {
                return { ...v, fontSize: ".80rem" };
            },
            singleValue: (v, s) => {
                return { ...v, fontSize: ".80rem" };
            },
            placeholder: (v) => {
                return { ...v, fontSize: ".80rem" };
            },
            menu: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, borderTopLeftRadius: "0", borderTopRgithRadius: "0", marginBottom: "0", zIndex: 10 };
            },
            menuList: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, zIndex: 10, fontSize: ".80rem" };
            },
        };
    }

    const reactSelectStylesPromo = () => {
        return {
            option: (v) => {
                return { ...v };
            },
            input: (v) => {
                return { ...v, paddingRight: 40, fontSize: ".80rem" };
            },
            singleValue: (v) => {
                return { ...v, paddingRight: 40, fontSize: ".80rem" };
            },
            placeholder: (v) => {
                return { ...v, paddingRight: 40, fontSize: ".80rem" };
            },
            menu: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, borderTopLeftRadius: "0", borderTopRgithRadius: "0", marginBottom: "0", zIndex: 10 };
            },
            menuList: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, zIndex: 10, fontSize: ".80rem" };
            },
        };
    }

    const reactSelectStylesWithIcon = () => {
        return {
            option: (v) => {
                return { ...v };
            },
            input: (v) => {
                return { ...v, paddingLeft: 45, fontSize: ".80rem" };
            },
            singleValue: (v) => {
                return { ...v, paddingLeft: 45, fontSize: ".80rem" };
            },
            placeholder: (v) => {
                return { ...v, paddingLeft: 45, fontSize: ".80rem" };
            },
            menu: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, borderTopLeftRadius: "0", borderTopRgithRadius: "0", marginBottom: "0", zIndex: 10 };
            },
            menuList: (v) => {
                return { ...v, marginTop: 0, paddingTop: 0, zIndex: 10, fontSize: ".80rem" };
            },
        };
    }

    const t = (array) => {
        var translations;
        if (typeof array === "string") {
            translations = JSON.parse(array);
        } else if (array === undefined) {
            return "";
        } else {
            translations = array;
        }

        if (localStorage.getItem("i18nextLng") === "zh_CN") {
            return translations["zh-CN"] ?? translations["en"];
        }
        return translations[localStorage.getItem("i18nextLng")] ?? translations["en"];
    }

    const user_benefits = {
        blue: {
            slot: "0.7",
            sportsbook: "0.6",
            live_dealer: "0.6",
            benefits: [
                "reward.carousel.slot_rebate",
                "reward.carousel.sportsbook_rebate",
                "reward.carousel.live_dealer_rebate",
                "reward.carousel.welcome_bonuses",
                "reward.carousel.24hours_customers_service",
            ],
        },
        silver: {
            slot: "0.8",
            sportsbook: "0.7",
            live_dealer: "0.7",
            benefits: [
                "reward.carousel.slot_rebate",
                "reward.carousel.sportsbook_rebate",
                "reward.carousel.live_dealer_rebate",
                "reward.carousel.exclusive_promotions",
                "reward.carousel.birthday_bonus",
                "reward.carousel.surprise_bonus",
                "reward.carousel.24hours_customers_service",
            ],
        },
        gold: {
            slot: "0.9",
            sportsbook: "0.8",
            live_dealer: "0.8",
            benefits: [
                "reward.carousel.slot_rebate",
                "reward.carousel.sportsbook_rebate",
                "reward.carousel.live_dealer_rebate",
                "reward.carousel.exclusive_promotions",
                "reward.carousel.exclusive_deposit_methods",
                "reward.carousel.birthday_bonus",
                "reward.carousel.surprise_bonus",
                "reward.carousel.vip_gifts",
                "reward.carousel.higher_table_limites",
                "reward.carousel.special_access_to_vip_tables",
                "reward.carousel.personalised_vip_offers",
                "reward.carousel.24hours_customers_service",
            ],
        },
        platinum: {
            slot: "1.0",
            sportsbook: "0.9",
            live_dealer: "0.9",
            benefits: [
                "reward.carousel.slot_rebate",
                "reward.carousel.sportsbook_rebate",
                "reward.carousel.live_dealer_rebate",
                "reward.carousel.exclusive_promotions",
                "reward.carousel.exclusive_deposit_methods",
                "reward.carousel.birthday_bonus",
                "reward.carousel.surprise_bonus",
                "reward.carousel.vip_gifts",
                "reward.carousel.higher_table_limites",
                "reward.carousel.special_access_to_vip_tables",
                "reward.carousel.personalised_vip_offers",
                "reward.carousel.fast_payout",
                "reward.carousel.24hours_priorities_customers_service",
            ],
        },
        legend: {
            slot: "1.2",
            sportsbook: "1.0",
            live_dealer: "1.0",
            benefits: [
                "reward.carousel.slot_rebate",
                "reward.carousel.sportsbook_rebate",
                "reward.carousel.live_dealer_rebate",
                "reward.carousel.exclusive_promotions",
                "reward.carousel.exclusive_deposit_methods",
                "reward.carousel.birthday_bonus",
                "reward.carousel.surprise_bonus",
                "reward.carousel.vip_gifts",
                "reward.carousel.higher_table_limites",
                "reward.carousel.special_access_to_vip_tables",
                "reward.carousel.personalised_vip_offers",
                "reward.carousel.fast_payout",
                "reward.carousel.24hours_priorities_customers_service",
            ],
        }
    };

    return {
        number_format,
        getRank,
        getRankImage,
        getNextRank,
        getPreviousRank,
        getNextRankImage,
        getTransactionType,
        getWalletType,
        getTransactionStatus,
        getPaymentType,
        getPaymentCategory,
        getBanks,
        reactSelectStyles,
        reactSelectStylesWithIcon,
        reactSelectStylesPromo,
        t,
        user_benefits,
        getLocale,
        walletTypes,
        getCryptos,
        getEWallets
    };
}